.historial {
  margin: auto;
  display: block;
  max-width: 306px;
}
.historial__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
  margin-top: 9px;
}
.historial__titulo {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
  margin-top: 9px;
}
.historial-btn {
    margin-top: 9px;
}
