.cuenta {
  width: 320px;
  display:block;
  margin: auto;
}
.cuenta__item {
  position: relative;
  padding-top: 9px;
  padding-left: 25px;
}
.cuenta__item-titulo{
  position: relative;
  padding-top: 9px;
}
.cuenta__link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: black;
  text-decoration-line: underline;
}
.cuenta__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
}
.cuenta__titulo-secundario {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #686868;
  margin: 0px;
}
.cuenta__data {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  color: #000000;
}
.cuenta__line {
  display: block;
  width: 270px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.cuenta__btn {
  margin-top: 9px;
}
.cuenta__link-btn {
  all:unset
}