.editar-cliente {
  margin: auto;
  display: block;
  max-width: 306px;
  padding: 9px;
}
.editar-cliente__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  margin-left: 93px;
  display: inline-block;
}
.editar-cliente__titulo {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  padding-top: 7px;
}
.editar-cliente__btn {
  margin-top: 9px;
}
