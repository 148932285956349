.editar-ubicacion {
    margin: auto;
    display: block;
    max-width: 306px;
    padding: 9px;
  }
  .editar-ubicacion__titulo-principal {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    margin-left: 93px;
    display: inline-block;
  }
  .editar-ubicacion__titulo {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    margin: 0px;
    padding-top: 7px;
  }
  .editar-ubicacion__btn {
    margin-top: 9px;
  }
  