.detalle-de-pedido {
  margin: auto;
  display: block;
  max-width: 306px;
  padding: 9px;
}
.detalle-de-pedido__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  margin-left: 37px;
  display: inline-block;
}

