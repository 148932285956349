.reservar-torta {
  margin: auto;
  display: block;
  max-width: 306px;
}
.center-reservas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 7px;
  align-items: center;
}
.center-reservas-porciones {
  display: block;
  margin-bottom: 5px;
  align-items: center;
}
.reservar-torta__container-parametro {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.reservar-torta__container-tiempo {
  display: grid;
  grid-template-columns: min-content 100px;
  column-gap: 9px;
}
.reservar-torta__container-precio {
  display: grid;
  grid-template-columns: 78px 78px;
  column-gap: 9px;
}
.reservar-torta__btn {
  margin-top: 9px;
}
.titulo-reservas {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  padding-top: 7px;
}
.titulo-reservas--igual {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
}
.icono-agregar {
  background-image: url("../../../recursos/img/plus.svg");
  width: 18px;
  height: 18px;
}
.icono-agregar-cliente {
  background-image: url("../../../recursos/img/agregar-cliente.svg");
  width: 22px;
  height: 22px;
}
.icono-edit {
  background-image: url("../../../recursos/img/edit.svg");
  width: 18px;
  height: 18px;
}
