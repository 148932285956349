.productos {
    margin: auto;
    display: block;
    max-width: 306px;
    padding: 9px;
  }
  .productos__titulo {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin: auto;
    width: fit-content;
    display: block;
    margin-top: 9px;
  }