.text-area {
    width: 306px;
    height: 74px;
    background-color: white;
    padding-left: 6px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}