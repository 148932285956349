.pedidos {
  margin: auto;
  display: block;
  max-width: 306px;
}
.pedidos__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  display: block;
  margin: auto;
  width: fit-content;
  margin-bottom: 9px;
  padding-top: 9px;
}
.pedidos-btn {
  margin-top: 9px;
}

