.cardfilters-container {
  display: grid;
  grid-template-columns: 81px 81px;
  column-gap: 5px;
  justify-content: center;
  margin-top: 9px;
}
.cardfilter {
  position: relative;
  display: block;
  margin: auto;
  background-color: white;
  width: 81px;
  height: 99px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.cardfilter__grid {
  padding-top: 7px;
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
}
.center {
  display: flex;
  align-items: center;
}
.titulo-filter {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;

  color: black;
}
.titulo-filter-de {
  margin: 0;
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;

  color: black;
}
.up-arrow {
  background-image: url("../../../recursos/img/north.svg");
  height: 18px;
  width: 18px;
}
.down-arrow {
    background-image: url("../../../recursos/img/south.svg");
    height: 18px;
    width: 18px;
  }
.checkbox-filter {
  height: 10px;
  width: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 5px;
}
