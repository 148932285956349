.cliente-info {
  margin: auto;
  display: block;
  max-width: 306px;
  padding: 9px;
}
.cliente__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  margin-left: 93px;
  display: inline-block;
}
.cliente__titulo-secundario {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
  margin-top: 9px;
}
.cliente-btn {
  margin-top: 9px;
}
