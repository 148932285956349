.productos-arreglos__item {
  margin-top: 9px;
}
.producto-arreglos__titulo {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  padding-top: 7px;
}
