.select {
    height: 28px;
    background-color: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.select--xxlarge {
    width: 250px;
}
.select--xlarge {
    width: 192px;
}
.select--large {
    width: 146px;
}
.select--medium {
    width: 104px;
}