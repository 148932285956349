.navbar {
  margin: auto;
  min-width: 320px;
  height: 78px;
  background-color: black;
}
.navbar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 14px;
}
.navbar-login__container {
  padding: 28px 10px 29px 10px;
}
.logo {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  margin: 0;
}
.navbar__tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  margin-top: 14px;
}
.link {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  color: white;
}
