.recover-password__item-titulo {
  width: fit-content;
  display: block;
  margin: auto;
  margin-top: 71px;
}
.recover-password__item-container {
  margin-top: 38px;
}
.recover-password__item {
  width: fit-content;
  display: block;
  margin: auto;
  margin-top: 11px;
}
.recover-password__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
}
.recover-password__titulo-secundario {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: black;
  margin: 0px;
}
.recover-password__btn {
  margin-top: 38px;
}
.recover-password__link {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #000000;
}
