.reservar-arreglos {
  margin: auto;
  display: block;
  max-width: 306px;
}
.reservar-bocaditos__container-cupcakes {
  display: grid;
  grid-template-columns: 28px 105px 105px;
  column-gap: 9px;
}
.reservar-arreglos__container-bocaditos {
  display: grid;
  grid-template-columns: 0.3fr 0.2fr 0.3fr;
  column-gap: 9px;
}
.reservar-arreglos__container-precio {
  display: grid;
  grid-template-columns: 78px 78px;
  column-gap: 9px;
}
.reservar-arreglos__container-tiempo {
  display: grid;
  grid-template-columns: min-content 100px;
  column-gap: 9px;
}
.reservar-arreglos__btn {
  margin-top: 9px;
}
.center-reservas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 7px;
  align-items: center;
}
.titulo-reservas {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  padding-top: 7px;
}
.icono-agregar {
  background-image: url("../../../recursos/img/plus.svg");
  width: 18px;
  height: 18px;
}
.icono-agregar-cliente {
  background-image: url("../../../recursos/img/agregar-cliente.svg");
  width: 22px;
  height: 22px;
}
.icono-edit {
  background-image: url("../../../recursos/img/edit.svg");
  width: 18px;
  height: 18px;
}
.reservas-arreglos__line {
  display: block;
  margin: auto;
  width: 95%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 9px;
  margin-bottom: 9px;
}
