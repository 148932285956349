/* clase principal con el contenedor que sirve de base para todos*/
.card {
  position: relative;
  display: block;
  margin: auto;
  background-color: white;
  width: 300px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-top: 9px;
}
/* tamaño para card info del cliente*/
.card__client-info {
  min-height: 168px;
}
/* clase info del cliente con color alternativo negro*/
.card__client-info--black {
  background-color: black;
  color: white;
}
/* clase principal contenedora del grid para distribuir el espacio en columnas y filas*/
.card__inner {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  display: grid;
  grid-template-rows: auto;
}
/* clase distribucion de info del cliente*/
.card__client-info__inner {
  padding: 14px 25px 14px 25px;
  grid-template-columns: 2fr 1fr;
  column-gap: 22px;
}
/* tamaño para card info del delivery*/
.card__delivery-info {
  min-height: 128px;
}
/* clase info del delivery color alternativo negro*/
.card__delivery-info--black {
  background-color: black;
  color: white;
}
/* clase distribucion de info del delivery*/
.card__delivery-info__inner {
  padding: 9px 25px 14px 25px;
  column-gap: 8px;
}
/*clases de texto*/
.titulo {
  margin: 0;
  color: #686868;
}
.data {
  margin: 0 0 5px 0;
  word-wrap: break-word;
  word-break: break-word;
}
.email {
  word-break: break-all;
}
.tema-data {
  margin: 0 0 5px 0;
  word-wrap: break-word;
  width: 250px;
}
/*-----------------------*/
/* tamaño para card info de la order*/
.card__order-info {
  min-height: 163px;
}
/* clase info de la orden color alternativo negro*/
.card__order-info--black {
  background-color: black;
  color: white;
}
/* clase distribucion de info de la orden*/
.card__order-info__inner {
  padding: 14px 25px 14px 25px;
}
.card__order-info-bocadito__inner {
  padding: 14px 25px 14px 25px;
}
.card__all-order-info {
  min-height: 388px;
}
.card__all-order-info__inner-client {
  padding: 14px 25px 9px 25px;
}
.card__all-order-info__inner-order {
  padding: 9px 25px 9px 25px;
}
.line {
  display: block;
  margin: auto;
  width: 95%;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.line-orders {
  display: block;
  margin: auto;
  width: 95%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 5px;
  margin-bottom: 5px;
}
/* clase boton edit*/
.edit {
  position: absolute;
  right: 2px;
  bottom: 3px;
  background-image: url("../../recursos/img/edit.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}
.info {
  position: absolute;
  right: 2px;
  top: 3px;
  background-image: url("../../recursos/img/info.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}
.info--white {
  background-image: url("../../recursos/img/info-white.svg");
}
.edit-container {
  position: relative;
}
/* clase boton edit blanco*/
.edit--white {
  background-image: url("../../recursos/img/edit-white.svg");
}
/* clase boton map*/
.map {
  width: 42px;
  height: 42px;
  background-image: url("../../recursos/img/map.svg");
}
/* clase boton map blanco*/
.map--white {
  background-image: url("../../recursos/img/map-white.svg");
}
.detalle-de-pedido__link {
  all: unset;
}
.container-grid-dos {
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 56px;
}
.container-grid-dos-pequeño {
  display: grid;
  grid-template-columns: 34px 42px;
  column-gap: 10px;
}

.container-grid-tres {
  display: grid;
  grid-template-columns: 60px 28px 60px;
  column-gap: 50px;
}
.container-grid-arreglo {
  display: grid;
  grid-template-columns: 135px 120px;
  column-gap: 9px;
}
.container-grid-arreglo-caja-dulces {
  display: grid;
  grid-template-columns: 70px;
}
.container-grid-arreglo-item {
  display: grid;
  grid-template-columns: 70px 30px;
  column-gap: 0px;
}
.container-grid-arreglo-tema {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  grid-template-rows: auto;
}
.container-grid-arreglo-item-caja-dulces {
  display: grid;
  grid-template-columns: 70px 35px 70px 70px;
  column-gap: 0px;
}
.container-grid-tres-torta {
  display: grid;
  grid-template-columns: 82px 72px 72px;
  column-gap: 10px;
}
.container-grid-dos-grande {
  display: grid;
  grid-template-columns: 142px 95px;
  column-gap: 20px;
}
.container-grid-dos-mapa {
  display: grid;
  grid-template-columns: 186px 42px;
  column-gap: 16px;
}
