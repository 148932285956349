.search-bar__container {
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 9px;
}
.search-bar {
    width: 240px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 6px;
    background-color: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
}
.search-bar input {
    all: unset;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    width: inherit;
}
.lupa {
    width: 13px;
    height: 13px;
    background-image: url("../../../recursos/img/lupa.svg");
    position: relative;
    right: 5px;
    bottom: 1px;
}