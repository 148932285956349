.cuenta-editar {
  width: 320px;
  display: block;
  margin: auto;
}
.cuenta-editar__item-titulo {
  position: relative;
  padding-top: 9px;
}
.cuenta-editar__titulo-principal {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  width: fit-content;
  display: block;
}
.cuenta-editar__titulo-secundario {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #686868;
  margin: 0px;
}
.cuenta-editar__item {
  position: relative;
  padding-top: 9px;
  padding-left: 7px;
}
.cuenta-editar__btn {
  margin-top: 9px;
}
.cuenta-editar__link {
  all: unset;
}

.error {
  color: #bf1650;
  font-size: 13px;
  margin-bottom: 0;
}

.error::before {
  display: inline;
  content: "⚠ ";
}
