.navtab {
  display: block;
  margin: auto;
  width: 316px;
  height: 26px;
  background: #000000;
  border-radius: 6px;
  margin-top: 2px;
}
.navtab--historial{
  width: 210px;
}
.navtabs__container {
  display: flex;
  padding: 2px 3px 2px 3px;
}
