/* clase principal con el contenedor que sirve de base para todos*/
.card-productos {
  position: relative;
  display: block;
  margin: auto;
  background-color: white;
  width: 300px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
/* tamaño para card info del tamaño*/
.card__producto-tamano {
  min-height: 143px;
}
/* clase principal contenedora del grid para distribuir el espacio en columnas y filas*/
.card-productos__inner {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  display: grid;
}
/* clase distribucion de info del tamaño*/
.card__producto-tamano__inner {
  padding: 14px 25px 6px 25px;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;
}
.card__producto-arreglo__inner {
  padding: 14px 25px 6px 25px;
  grid-template-rows: auto;
  row-gap: 9px;
}
.card__producto-arreglo__inner-item {
  display: grid;
  grid-template-columns: 100px 40px;
  column-gap: 110px;
}
.card__producto-arreglo-edit__inner {
  padding: 14px 6px 6px 6px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  row-gap: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}
.card__producto-arreglo-edit__inner-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
}
.tamano-edit {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.numero-porciones-edit {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.precio-edit {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.card__producto-parametro-tamano {
  min-height: 123px;
  position: relative;
}
.card__producto-parametro__inner {
  padding: 14px 25px 14px 25px;
  grid-template-columns: 1fr 1fr 1fr;
}
.card__producto-bocadito__inner {
  padding: 14px 25px 14px 25px;
  grid-template-columns: 1fr 1fr 2fr;
}
.card__producto-bocadito-edit__inner {
  padding: 14px 10px 5px 18px;
  grid-template-columns: 147px 120px;
  column-gap: 46px;
}
.card__producto-bocadito-edit__inner-pequeno {
  padding: 0px 10px 5px 18px;
  grid-template-columns: 125px 125px;
  column-gap: 16px;
}
/*clases de texto*/
.titulo-card {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
}
.titulo-card__input {
  margin-top: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 2px;
}
.titulo {
  margin: 0;
  color: #686868;
}
.data {
  margin: 0 0 5px 0;
  word-wrap: break-word;
}
/*--------------------------*/
/* clase boton edit*/
.card-productos__edit {
  position: absolute;
  right: 2px;
  bottom: 2px;
  background-image: url("../../../recursos/img/edit.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}
.plus {
  background-image: url("../../../recursos/img/plus.svg");
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.delete {
  background-image: url("../../../recursos/img/remove_circle_outline.svg");
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.delete-card-productos-bocaditos {
  background-image: url("../../../recursos/img/remove_circle_outline.svg");
  width: 18px;
  height: 18px;

  margin-right: 5px;
}
/*clase checkbox*/
.checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 5px;
}
.checkbox-bocaditos {
  position: absolute;
  top: 14px;
  left: 7px;
}
.btn-guardar {
  grid-column-start: 1;
  grid-column-end: 6;
}
.btn-eliminar {
  padding-bottom: 5px;
  grid-column-start: 1;
  grid-column-end: 6;
}
.card__producto-parametro-edit__inner {
  padding: 14px 25px 14px 25px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
.btn-guardar-parametro {
  padding-bottom: 5px;
  grid-column-start: 1;
  grid-column-end: 3;
}
.btn-eliminar-categoria {
  position: absolute;
  padding: 0px;
  margin: 0px;
  right: 2px;
  top: 15px;
  width: 120px;
  height: 18px;
}
.btn-eliminar-bocadito {
  position: absolute;
  display: block;
  padding: 0px;
  margin: 0px;
  right: 0px;
  top: -10px;
  width: 120px;
  height: 18px;
}
.card-productos-arreglos-edit-container {
  position: relative;
}
.card-productos-tortas-edit-container {
  position: relative;
}
.line {
  display: block;
  margin: auto;
  width: 95%;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.bocaditos__precio {
  display: grid;
  grid-template-rows: auto;
}
