/* clase principal boton */
.btn {
    display:block;
    margin: auto;
    background-color: black;
    border: none;
    border-radius: 15px;
    
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;  
}
 
/* variantes de boton */
.btn--large {
    width: 306px;
    height: 33px;

    font-size: 18px;
    line-height: 21px; 
}

.btn--medium {
    width: 306px;
    height: 24px;

    font-size: 13px;
    line-height: 15px;
}

.btn--small {
    width: 306px;
    height: 16px;

    font-size: 13px;
    line-height: 15px;
}

.btn--xsmall {
    width: 216px;
    height: 16px;

    font-size: 13px;
    line-height: 15px;
}

.btn--large-short {
    width: 170px;
    height: 33px;

    font-size: 18px;
    line-height: 21px;
}

.btn__select {
    width: 102px;
    height: 22px;
    background: black;
    color: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
  }
  .btn__select--white {
    background: #ffffff;
    color: black;
  }
  .btn__back {
      
      width: 24px;
      height: 24px;
      background-color: black;
      border: none;
      border-radius: 6px;
  }
  .back-arrow{
      margin: 2px 3px 2px 1px;
      position: relative;
      background-image: url("../../recursos/img/back-arrow.svg");
      width: 9px;
      height: 16px;
  }

  